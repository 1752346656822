import React from 'react'
import AppWrapper from '../app/layouts/AppWrapper'
import BlogLandingData from '../app/components/BlogLandingData'
import BlogLandingPage from '../ui/pages/BlogLandingPage'
import HelmetComponent from '../ui/layouts/HelmetComponent'
import metaTags from '../config/meta-tags/en.json'

const Page = props => (
  <>
    <HelmetComponent
      title={metaTags.blogTitle}
      description={metaTags.blog}
      page="de/blog"
    />
    <AppWrapper {...props} lang="de">
      <BlogLandingData>
        {({ blogroll, blog, news, changelog, comparison }) => (
          <BlogLandingPage
            blogroll={blogroll}
            generalItems={blog}
            newsItems={news}
            productItems={changelog}
            comparisonItems={comparison}
          />
        )}
      </BlogLandingData>
    </AppWrapper>
  </>
)

export default Page
